// noinspection IncrementDecrementResultUsedJS,ReuseOfLocalVariableJS

const UNKNOWN_64_LIMIT = 64;
const ASCII_RANGE_LIMIT = 128;
const EMAIL_MAX_LENGTH_EXCLUSIVE = 255;
const ATOM_CHARACTERS = "!#$%&'*+-/=?^_`{|}~";

class EmailValidator {
  index: number;

  constructor() {
    this.index = 0;
  }

  isLetterOrDigit(c) {
    return (c >= 'A' && c <= 'Z') || (c >= 'a' && c <= 'z') || (c >= '0' && c <= '9');
  }

  isAtom(c, allowInternational) {
    return c.charCodeAt(0) < ASCII_RANGE_LIMIT
      ? this.isLetterOrDigit(c) || ATOM_CHARACTERS.indexOf(c) !== -1
      : allowInternational;
  }

  isDomain(c, allowInternational) {
    return c.charCodeAt(0) < ASCII_RANGE_LIMIT ? this.isLetterOrDigit(c) || c === '-' : allowInternational;
  }

  skipAtom(text, allowInternational) {
    const startIndex = this.index;

    while (this.index < text.length && this.isAtom(text[this.index], allowInternational)) {
      this.index++;
    }

    return this.index > startIndex;
  }

  skipSubDomain(text, allowInternational) {
    const startIndex = this.index;

    if (!this.isDomain(text[this.index], allowInternational) || text[this.index] === '-') {
      return false;
    }

    this.index++;

    while (this.index < text.length && this.isDomain(text[this.index], allowInternational)) {
      this.index++;
    }

    return this.index - startIndex < UNKNOWN_64_LIMIT && text[this.index - 1] !== '-';
  }

  skipDomain(text, allowTopLevelDomains, allowInternational) {
    if (!this.skipSubDomain(text, allowInternational)) {
      return false;
    }

    if (this.index < text.length && text[this.index] === '.') {
      do {
        this.index++;

        if (this.index === text.length) {
          return false;
        }

        if (!this.skipSubDomain(text, allowInternational)) {
          return false;
        }
      } while (this.index < text.length && text[this.index] === '.');
    } else if (!allowTopLevelDomains) {
      return false;
    }

    return true;
  }

  skipQuoted(text, allowInternational) {
    let escaped = false;

    // skip over leading '"'
    this.index++;

    while (this.index < text.length) {
      if (text.charCodeAt(this.index) >= ASCII_RANGE_LIMIT && !allowInternational) {
        return false;
      }

      if (text[this.index] === '\\') {
        escaped = !escaped;
      } else if (!escaped) {
        if (text[this.index] === '"') {
          break;
        }
      } else {
        escaped = false;
      }

      this.index++;
    }

    if (this.index >= text.length || text[this.index] !== '"') {
      return false;
    }

    this.index++;

    return true;
  }

  skipWord(text, allowInternational) {
    if (text[this.index] === '"') {
      return this.skipQuoted(text, allowInternational);
    }

    return this.skipAtom(text, allowInternational);
  }

  skipIPv4Literal(text) {
    let groups = 0;

    while (this.index < text.length && groups < 4) {
      const startIndex = this.index;
      let value = 0;

      while (this.index < text.length && text[this.index] >= '0' && text[this.index] <= '9') {
        // Coercion of the '0' to its character code is expected with the substraction on the next line
        // eslint-disable-next-line
        // @ts-ignore
        value = value * 10 + (text[this.index] - '0');
        this.index++;
      }

      if (this.index === startIndex || this.index - startIndex > 3 || value > EMAIL_MAX_LENGTH_EXCLUSIVE) {
        return false;
      }

      groups++;

      if (groups < 4 && this.index < text.length && text[this.index] === '.') {
        this.index++;
      }
    }

    return groups === 4;
  }

  isHexDigit(c) {
    return (c >= 'A' && c <= 'F') || (c >= 'a' && c <= 'f') || (c >= '0' && c <= '9');
  }

  // This needs to handle the following forms:
  //
  // IPv6-addr = IPv6-full / IPv6-comp / IPv6v4-full / IPv6v4-comp
  // IPv6-hex  = 1*4HEXDIG
  // IPv6-full = IPv6-hex 7(":" IPv6-hex)
  // IPv6-comp = [IPv6-hex *5(":" IPv6-hex)] "::" [IPv6-hex *5(":" IPv6-hex)]
  //             ; The "::" represents at least 2 16-bit groups of zeros
  //             ; No more than 6 groups in addition to the "::" may be
  //             ; present
  // IPv6v4-full = IPv6-hex 5(":" IPv6-hex) ":" IPv4-address-literal
  // IPv6v4-comp = [IPv6-hex *3(":" IPv6-hex)] "::"
  //               [IPv6-hex *3(":" IPv6-hex) ":"] IPv4-address-literal
  //             ; The "::" represents at least 2 16-bit groups of zeros
  //             ; No more than 4 groups in addition to the "::" and
  //             ; IPv4-address-literal may be present
  skipIPv6Literal(text) {
    let compact = false;
    let colons = 0;

    while (this.index < text.length) {
      let startIndex = this.index;

      while (this.index < text.length && this.isHexDigit(text[this.index])) {
        this.index++;
      }

      if (this.index >= text.length) {
        break;
      }

      if (this.index > startIndex && colons > 2 && text[this.index] === '.') {
        // IPv6v4
        this.index = startIndex;

        if (!this.skipIPv4Literal(text)) {
          return false;
        }

        return compact ? colons < 6 : colons === 6;
      }

      let count = this.index - startIndex;
      if (count > 4) {
        return false;
      }

      if (text[this.index] !== ':') {
        break;
      }

      startIndex = this.index;
      while (this.index < text.length && text[this.index] === ':') {
        this.index++;
      }

      count = this.index - startIndex;
      if (count > 2) {
        return false;
      }

      if (count === 2) {
        if (compact) {
          return false;
        }

        compact = true;
        colons += 2;
      } else {
        colons++;
      }
    }

    if (colons < 2) {
      return false;
    }

    return compact ? colons < 7 : colons === 7;
  }

  validate(email, allowTopLevelDomains = false, allowInternational = false) {
    if (!email) {
      return false;
    }

    if (!email.length || email.length >= EMAIL_MAX_LENGTH_EXCLUSIVE) {
      return false;
    }

    if (!this.skipWord(email, allowInternational) || this.index >= email.length) {
      return false;
    }

    while (email[this.index] === '.') {
      this.index++;

      if (this.index >= email.length) {
        return false;
      }

      if (!this.skipWord(email, allowInternational)) {
        return false;
      }

      if (this.index >= email.length) {
        return false;
      }
    }

    if (this.index + 1 >= email.length || this.index > UNKNOWN_64_LIMIT || email[this.index++] !== '@') {
      return false;
    }

    if (email[this.index] !== '[') {
      // domain
      if (!this.skipDomain(email, allowTopLevelDomains, allowInternational)) {
        return false;
      }

      return this.index === email.length;
    }

    // address literal
    this.index++;

    // we need at least 8 more characters
    if (this.index + 8 >= email.length) {
      return false;
    }

    const ipv6 = email.substring(this.index, 5);
    if (ipv6.toLowerCase() === 'ipv6:') {
      this.index += 'IPv6:'.length;
      if (!this.skipIPv6Literal(email)) {
        return false;
      }
    } else if (!this.skipIPv4Literal(email)) {
      return false;
    }

    if (this.index >= email.length || email[this.index++] !== ']') {
      return false;
    }

    return this.index === email.length;
  }
}

/**
 * Function which validates an email per RFC 3696.  See https://www.rfc-editor.org/rfc/rfc3696#section-3 for format details.
 *
 * @param email
 * @param allowInternational
 * @param allowTopLevelDomains
 */
export const isEmailValid = (email: string, allowInternational = false, allowTopLevelDomains = false): boolean => {
  const vld = new EmailValidator();
  return vld.validate(email, allowTopLevelDomains, allowInternational);
};
